import Cookies from 'js-cookie';
import { round, extend, capitalize } from 'lodash';
import React, { useState, useEffect } from 'react';

import { SelectableValue, AppEvents, getTimeZoneInfo } from '@grafana/data';
import { getTemplateSrv, getDataSourceSrv } from '@grafana/runtime';
import { ToolbarButton, ButtonSelect } from '@grafana/ui';
import appEvents from 'app/core/app_events';
import InfluxDatasource from 'app/plugins/datasource/influxdb/datasource';
import { useDispatch } from 'app/types';

import { getTimeSrv } from '../../services/TimeSrv';
import { DashboardModel } from '../../state';

enum DownloadState {
  Error = 'error',
  Aggregated = 'aggregated',
  Raw = 'raw',
  Success = 'success',
  Initial = '',
}

type DownloadCSVProps = {
  dashboard: DashboardModel;
};

const TRANSFER_START_SIGNAL_COOKIE = 'transferStarted';
const DOWNLOAD_IFRAME_ID = '#downloadIframe';
const MAX_ALLOWED_DAYS_DOWNLOAD = 367;

const getQueryUrl = async (dashboard: DashboardModel, downloadState: DownloadState): Promise<string> => {
  const isAggregated = downloadState === DownloadState.Aggregated;
  const agg = isAggregated ? '$Aggregation("value") as value' : 'value';
  const aggInterval = isAggregated ? ', time($Interval)' : '';
  const fill = isAggregated ? 'fill(null)' : '';

  const query = `
    SELECT ${agg} FROM "measurements"
    WHERE "uqk" =~ /^$uqk$/ AND $timeFilter
    GROUP BY "uqk" ${aggInterval} ${fill}
  `;

  const templateSrv = getTemplateSrv();
  let interpolated = templateSrv.replace(query, undefined, 'regex').replace('$Aggregation', 'mean');

  return getDataSourceSrv()
    .get(dashboard.downloadsDatasource)
    .then((ds) => {
      const range = getTimeSrv().timeRange();

      const days = round((range.to.unix() - range.from.unix()) / 60 / 60 / 24);
      if (days > MAX_ALLOWED_DAYS_DOWNLOAD) {
        appEvents.emit(AppEvents.alertError, [
          `Only ${MAX_ALLOWED_DAYS_DOWNLOAD} days of download allowed`,
          `${days} days requested`,
        ]);
        return '';
      }

      const ix = ds as InfluxDatasource; // eslint-disable-line
      const timeFilter = ix.getTimeFilter({ rangeRaw: range, timezone: 'UTC' }); // TODO: check if UTC is correct
      interpolated = interpolated.replace(/\$timeFilter/g, timeFilter);
      let data = { q: interpolated, epoch: 'ms' };

      const currentUrl = ix.urls.shift();
      if (currentUrl) {
        ix.urls.push(currentUrl);
      }

      const devices = templateSrv
        .replace('$Node')
        .replace(/,/g, '-')
        .replace(/[\{\}]|(__)/g, '')
        .replace('$Node', '')
        .replace('$none', '')
        .replace('$all', '')
        .replace('.*', '')
        .substring(0, 50);
      const timestamp = [range.from, range.to].map((d) => d.local().format('YYYYMMDDHHmmss')).join('-');
      const parts = [dashboard.title.substring(0, 50), capitalize(downloadState), devices, timestamp];
      const fileName = parts.filter((n) => n).join('_');

      const tz = getTimeZoneInfo(dashboard.getTimezone(), Date.now());
      // eslint-disable-next-line
      const params: any = {
        u: ix.username,
        p: ix.password,
        format: 'csv',
        tzoffset: -(tz?.offsetInMins || 0),
        filename: fileName,
      };

      if (ix.database) {
        params.db = ix.database;
      }

      extend(params, data);
      // eslint-disable-next-line
      const options: any = {
        method: 'GET',
        url: currentUrl + '/query',
        params: params,
        precision: 'ms',
        inspect: { type: 'influxdb' },
        paramSerializer: ix.serializeParams,
      };

      options.headers = options.headers || {};
      if (ix.basicAuth) {
        options.headers.Authorization = ix.basicAuth;
      }
      const serializedParams = options.paramSerializer(options.params);
      if (serializedParams.length > 0) {
        options.url += (options.url.indexOf('?') === -1 ? '?' : '&') + serializedParams;
      }
      return options.url;
    });
};

export const DownloadCSV: React.FC<DownloadCSVProps> = ({ dashboard }) => {
  const dispatch = useDispatch();
  const [downloadState, setDownloadState] = useState(DownloadState.Initial);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    switch (downloadState) {
      case DownloadState.Initial:
        $(DOWNLOAD_IFRAME_ID).remove();
        return;
      case DownloadState.Error:
        appEvents.emit(AppEvents.alertError, ['Download error', errorMsg]);
        setDownloadState(DownloadState.Initial);
        return;
      case DownloadState.Success:
        appEvents.emit(AppEvents.alertSuccess, ['Download initiated']);
        setDownloadState(DownloadState.Initial);
        return;
    }

    getQueryUrl(dashboard, downloadState).then((url: string) => {
      if (!url) {
        setDownloadState(DownloadState.Initial);
        return;
      }

      if (!$(DOWNLOAD_IFRAME_ID).length) {
        $('body').append(
          "<iframe id='downloadIframe' sandbox='allow-same-origin allow-scripts allow-downloads' style='position:fixed;display:none;top:-100px;left:-100px;'/>"
        );
      }
      Cookies.set(TRANSFER_START_SIGNAL_COOKIE, 'false', { path: '/', sameSite: 'lax' });

      $(DOWNLOAD_IFRAME_ID).attr('src', url);

      const interval = setInterval(() => {
        const pre = $(DOWNLOAD_IFRAME_ID).contents().find('pre');
        if (pre?.length) {
          // assume error occurred if pre found
          const res = JSON.parse(pre.text());
          setErrorMsg(`${res.message}: ${res.error}`);
          setDownloadState(DownloadState.Error);
          clearInterval(interval);
          return;
        }

        const started = Cookies.get(TRANSFER_START_SIGNAL_COOKIE);
        if (started === 'true') {
          setErrorMsg('');
          setDownloadState(DownloadState.Success);
          clearInterval(interval);
          return;
        }
      }, 500);
    });
  }, [downloadState, dispatch, dashboard, errorMsg]);

  return downloadState ? (
    <ToolbarButton
      tooltip="Cancel Downloading CSV"
      onClick={() => setDownloadState(DownloadState.Initial)}
      icon="fa fa-spinner"
      key="button-start-download"
    />
  ) : (
    <ButtonSelect
      tooltip="Download data as CSV"
      options={[
        { label: 'Download CSV Aggregated', value: DownloadState.Aggregated },
        { label: 'Download CSV Raw', value: DownloadState.Raw },
      ]}
      onChange={
        (item: SelectableValue<string>) => setDownloadState((item.value as DownloadState) ?? DownloadState.Initial) // eslint-disable-line
      }
      icon="download-alt"
      key="button-cancel-download"
    />
  );
};

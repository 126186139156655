import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { chain, get } from 'lodash';

import { getInstanceState } from '../state/selectors';
import { VariablePayload, initialVariablesState, VariablesState } from '../state/types';
import { VariableRefresh, MetadataVariableModel, initialVariableModelState, VariableOption } from '../types';

interface VariableOptionsUpdate {
  variables: any; // eslint-disable-line
  metadata: any; // eslint-disable-line
}

export const initialMetadataVariableModelState: MetadataVariableModel = {
  ...initialVariableModelState,
  type: 'metadata',
  current: {} as VariableOption, // eslint-disable-line
  options: [],
  query: '',
  definition: '',
  referenceVariable: '',
  metadataVariable: '',
  refresh: VariableRefresh.onDashboardLoad,
};

export const metadataVariableSlice = createSlice({
  name: 'templating/metadata',
  initialState: initialVariablesState,
  reducers: {
    updateMetadataOptions: (state: VariablesState, action: PayloadAction<VariablePayload<VariableOptionsUpdate>>) => {
      const { metadata, variables } = action.payload.data;
      const instanceState = getInstanceState(state, action.payload.id);
      if (instanceState.type !== 'metadata') {
        return;
      }

      const refVar = chain(variables).find({ name: instanceState.referenceVariable }).value();
      const refVarValue = get(refVar, 'current.value');
      const value = get(get(metadata, refVarValue), instanceState.metadataVariable);
      instanceState.options = [{ text: String(value), value: value, selected: true }];
      instanceState.current = instanceState.options[0];
      instanceState.query = 'Metadata: value referred to the value of variable ' + refVar.name;
    },
  },
});

export const metadataVariableReducer = metadataVariableSlice.reducer;

export const { updateMetadataOptions } = metadataVariableSlice.actions;

import { pick } from 'lodash';
import { map } from 'rxjs/operators';

import { DataTransformerInfo /*, FieldMatcher, MatcherConfig*/ } from '../../types/transformations';

import { DataTransformerID } from './ids';

export interface MetadataTransformerOptions {
  supplementLabels?: string[];
  matchLabel?: string;
  overwrite: boolean;
}

export const metadataTransformer: DataTransformerInfo<MetadataTransformerOptions> = {
  id: DataTransformerID.metadata,
  name: 'Metadata supplements',
  description: 'Supplement with additional data defined in the metadata settings',
  defaultOptions: {
    overwrite: false,
  },

  /**
   * Return a modified copy of the series.  If the transform is not or should not
   * be applied, just return the input series
   */
  operator: (options) => (source) =>
    source.pipe(
      map((data) => {
        if (!Array.isArray(data) || data.length === 0) {
          return data;
        }

        return data.map((frame) => ({
          length: frame.length,
          meta: { ...frame.meta },
          fields: frame.fields.map((field) => {
            let labels = field.labels;
            if (
              field?.labels !== undefined &&
              options?.matchLabel !== undefined &&
              options.matchLabel in field.labels
            ) {
              const metadata = frame?.meta?.custom?.metadata;
              const selected = metadata[field.labels[options.matchLabel]];
              if (selected !== undefined) {
                const supplements = pick(selected, options.supplementLabels ?? Object.keys(selected));
                labels = options.overwrite ? { ...field.labels, ...supplements } : { ...supplements, ...field.labels };
              }
            }
            return { ...field, labels: labels };
          }),
        }));
      })
    ),
};

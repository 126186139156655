import { cloneDeep } from 'lodash';

import { dispatch } from '../../../store/store';
import { VariableAdapter } from '../adapters';
import { optionPickerFactory } from '../pickers';
import { setOptionAsCurrent, setOptionFromUrl } from '../state/actions';
import { MetadataVariableModel } from '../types';
import { toKeyedVariableIdentifier } from '../utils';

import { MetadataVariableEditor } from './MetadataVariableEditor';
import { updateMetadataVariableOptions } from './actions';
import { metadataVariableReducer, initialMetadataVariableModelState } from './reducer';

export const createMetadataVariableAdapter = (): VariableAdapter<MetadataVariableModel> => {
  return {
    id: 'metadata',
    description: 'Values are fetched from the metadata setting',
    name: 'Metadata',
    initialState: initialMetadataVariableModelState,
    reducer: metadataVariableReducer,
    picker: optionPickerFactory<MetadataVariableModel>(),
    editor: MetadataVariableEditor,
    dependsOn: (variable, variableToTest) => {
      return variable.referenceVariable === variableToTest.name;
    },
    setValue: async (variable, option, emitChanges = false) => {
      await dispatch(setOptionAsCurrent(toKeyedVariableIdentifier(variable), option, emitChanges));
    },
    setValueFromUrl: async (variable, urlValue) => {
      await dispatch(setOptionFromUrl(toKeyedVariableIdentifier(variable), urlValue));
    },
    updateOptions: async (variable) => {
      await dispatch(updateMetadataVariableOptions(toKeyedVariableIdentifier(variable)));
    },
    getSaveModel: (variable) => {
      const { index, id, state, global, current, options, ...rest } = cloneDeep(variable);
      return rest;
    },
    getValueForUrl: (variable) => {
      return variable.current.value;
    },
  };
};

import React, { FormEvent } from 'react';

import { SelectableValue } from '@grafana/data';
import { InlineField, InlineFieldRow, Input, Select } from '@grafana/ui';

import { ExpressionQuery } from '../types';

interface Props {
  labelWidth: number | 'auto';
  refIds: Array<SelectableValue<string>>;
  query: ExpressionQuery;
  onChange: (query: ExpressionQuery) => void;
}

export const Metadata = ({ labelWidth, onChange, refIds, query }: Props) => {
  const onRefIdChange = (value: SelectableValue<string>) => {
    onChange({ ...query, expression: value.value });
  };

  const onReferenceLabelChange = (event: FormEvent<HTMLInputElement>) => {
    onChange({
      ...query,
      window: event.currentTarget.value,
    });
  };

  const onValueTagChange = (event: FormEvent<HTMLInputElement>) => {
    onChange({
      ...query,
      upsampler: event.currentTarget.value,
    });
  };

  const referenceLabel = query.window;
  const valueTag = query.upsampler;

  return (
    <>
      <InlineFieldRow>
        <InlineField label="Input" labelWidth={labelWidth}>
          <Select onChange={onRefIdChange} options={refIds} value={query.expression} width={'auto'} />
        </InlineField>
      </InlineFieldRow>
      <InlineFieldRow>
        <InlineField label="Reference label" labelWidth={labelWidth}>
          <Input width={10} value={referenceLabel} onChange={(event) => onReferenceLabelChange(event)} />
        </InlineField>
        <InlineField label="Value tags (comma-separated)" labelWidth={labelWidth}>
          <Input width={30} value={valueTag} onChange={(event) => onValueTagChange(event)} />
        </InlineField>
      </InlineFieldRow>
    </>
  );
};

import React, { useMemo, useCallback } from 'react';

import {
  DataTransformerID,
  SelectableValue,
  standardTransformers,
  TransformerRegistryItem,
  TransformerUIProps,
} from '@grafana/data';
import { MetadataTransformerOptions } from '@grafana/data/src/transformations/transformers/metadata';
import { Stack } from '@grafana/experimental';
import { LegacyForms, InlineField, InlineFieldRow, Select, FilterPill } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';

export const MetadataTransformerEditor: React.FC<TransformerUIProps<MetadataTransformerOptions>> = ({
  input,
  options,
  onChange,
}) => {
  const labelWidth = 20;

  const { labelNames } = useMemo(() => {
    let labelNames: Array<SelectableValue<string>> = [];
    let uniqueLabels: Record<string, boolean> = {};

    for (const frame of input) {
      for (const field of frame.fields) {
        if (!field.labels) {
          continue;
        }

        for (const labelName of Object.keys(field.labels)) {
          if (!uniqueLabels[labelName]) {
            labelNames.push({ value: labelName, label: labelName });
            uniqueLabels[labelName] = true;
          }
        }
      }
    }

    return { labelNames };
  }, [input]);

  const { metadataNames, selected } = useMemo(() => {
    let metadataNames: Array<SelectableValue<any>> = [];
    let uniqueLabels: Record<string, boolean> = {};

    const metadata = getDashboardSrv().getCurrent()?.metadata || contextSrv.metadata;

    for (const key of Object.keys(metadata)) {
      for (const tag of Object.keys(metadata[key])) {
        if (!uniqueLabels[tag]) {
          metadataNames.push({ value: tag, label: tag });
          uniqueLabels[tag] = true;
        }
      }
    }

    const selected = new Set(options.supplementLabels?.length ? options.supplementLabels : Object.keys(uniqueLabels));
    return { metadataNames, selected };
  }, [options.supplementLabels]);

  const onMatchLabelChange = (value: SelectableValue<string> | null) => {
    onChange({ ...options, matchLabel: value?.value });
  };

  const onToggleOverwrite = useCallback(() => {
    onChange({
      ...options,
      overwrite: !options.overwrite,
    });
  }, [onChange, options]);

  const onToggleSelection = (v: string) => {
    if (selected.has(v)) {
      selected.delete(v);
    } else {
      selected.add(v);
    }
    if (selected.size === metadataNames.length || !selected.size) {
      const { supplementLabels, ...rest } = options;
      onChange(rest);
    } else {
      onChange({ ...options, supplementLabels: [...selected] });
    }
  };

  return (
    <>
      <InlineFieldRow>
        <InlineField label={'Match name'} labelWidth={labelWidth} tooltip="Select the tag name" htmlFor="tag-name">
          <Select
            menuShouldPortal
            inputId="tag-name"
            allowCustomValue={false}
            placeholder="Select label"
            options={labelNames}
            value={options?.matchLabel}
            onChange={onMatchLabelChange}
            className="min-width-16"
          />
        </InlineField>
      </InlineFieldRow>
      <InlineFieldRow>
        <InlineField label={'Labels'} labelWidth={labelWidth}>
          <Stack gap={1} wrap>
            {metadataNames.map((o, i) => {
              const label = o.label!;
              return (
                <FilterPill
                  key={`${label}/${i}`}
                  onClick={() => onToggleSelection(label)}
                  label={label}
                  selected={selected.has(label)}
                />
              );
            })}
          </Stack>
        </InlineField>
      </InlineFieldRow>
      <div className="gf-form-inline">
        <div className="gf-form">
          <LegacyForms.Switch
            label="Overwrite"
            labelClass="width-10"
            checked={!!options.overwrite}
            onChange={onToggleOverwrite}
          />
        </div>
      </div>
    </>
  );
};

export const metadataTransformRegistryItem: TransformerRegistryItem<MetadataTransformerOptions> = {
  id: DataTransformerID.metadata,
  editor: MetadataTransformerEditor,
  transformation: standardTransformers.metadataTransformer,
  name: standardTransformers.metadataTransformer.name,
  description: standardTransformers.metadataTransformer.description,
};

import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { SelectableValue } from '@grafana/data';

import { StoreState } from '../../../types';
import { VariableLegend } from '../editor/VariableLegend';
import { VariableSelectField } from '../editor/VariableSelectField';
import { initialVariableEditorState } from '../editor/reducer';
import { getMetadataVariableEditorState } from '../editor/selectors';
import { VariableEditorProps } from '../editor/types';
import { QueryVariableRefreshSelect } from '../query/QueryVariableRefreshSelect';
import { getVariablesState } from '../state/selectors';
import { MetadataVariableModel, VariableRefresh } from '../types';

import { initMetadataVariableEditor } from './actions';

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => {
  const { rootStateKey } = ownProps.variable;

  if (!rootStateKey) {
    console.error('MetadataVariableEditor: variable has no rootStateKey');
    return {
      extended: getMetadataVariableEditorState(initialVariableEditorState),
    };
  }

  const { editor } = getVariablesState(rootStateKey, state);

  return {
    extended: getMetadataVariableEditorState(editor),
  };
};

const mapDispatchToProps = {
  initMetadataVariableEditor,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface OwnProps extends VariableEditorProps<MetadataVariableModel> {}

type Props = OwnProps & ConnectedProps<typeof connector>;

export class MetadataVariableEditorUnConnected extends PureComponent<Props> {
  componentDidMount() {
    const { rootStateKey } = this.props.variable;
    if (!rootStateKey) {
      console.error('MetadataVariableEditor: variable has no rootStateKey');
      return;
    }

    this.props.initMetadataVariableEditor(rootStateKey);
  }

  onMetadataVariableChange = (option: SelectableValue<string>) => {
    this.props.onPropChange({
      propName: 'metadataVariable',
      propValue: option.value,
      updateOptions: true,
    });
  };

  onReferenceVariableChange = (option: SelectableValue<string>) => {
    this.props.onPropChange({
      propName: 'referenceVariable',
      propValue: option.value,
      updateOptions: true,
    });
  };

  onRefreshChange = (option: VariableRefresh) => {
    this.props.onPropChange({ propName: 'refresh', propValue: option });
  };

  render() {
    const { variable, extended } = this.props;
    console.log('extended', extended);
    const metadataOptions = extended?.metadataVariables?.length
      ? extended?.metadataVariables?.map((v: string) => ({ value: v, label: v }))
      : [];
    const metadataValue = metadataOptions.find((o) => o.value === variable.metadataVariable) ?? metadataOptions[0];

    const refVarOptions = extended?.referenceVariables?.length
      ? extended?.referenceVariables?.map((v: string) => ({ value: v, label: v }))
      : [];
    const refVarValue = refVarOptions.find((o) => o.value === variable.referenceVariable) ?? refVarOptions[0];

    return (
      <>
        <VariableLegend>Metadata binding options</VariableLegend>
        <VariableSelectField
          name="Value from"
          value={metadataValue}
          options={metadataOptions}
          onChange={this.onMetadataVariableChange}
        />
        <VariableSelectField
          name="Reference variable"
          value={refVarValue}
          options={refVarOptions}
          onChange={this.onReferenceVariableChange}
        />
        <QueryVariableRefreshSelect onChange={this.onRefreshChange} refresh={this.props.variable.refresh} />
      </>
    );
  }
}

export const MetadataVariableEditor = connector(MetadataVariableEditorUnConnected);

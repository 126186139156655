import { chain, keys } from 'lodash';

import { contextSrv } from 'app/core/services/context_srv';
import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';
import { ThunkResult } from 'app/types';

import { changeVariableEditorExtended } from '../editor/reducer';
import { validateVariableSelectionState } from '../state/actions';
import { toKeyedAction } from '../state/keyedVariablesReducer';
import { getVariable } from '../state/selectors';
import { KeyedVariableIdentifier } from '../state/types';
import { toVariablePayload } from '../utils';

import { updateMetadataOptions } from './reducer';

export interface MetadataVariableActionDependencies {
  getDashboardSrv: typeof getDashboardSrv;
}

export const updateMetadataVariableOptions = (
  identifier: KeyedVariableIdentifier,
  dependencies: MetadataVariableActionDependencies = { getDashboardSrv: getDashboardSrv }
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    try {
      const dashboard = getDashboardSrv().getCurrent();
      const metadata = dashboard?.metadata || contextSrv.metadata;
      const variableInState = getVariable(identifier, getState());
      const variables = chain(dashboard?.getVariables())
        .filter((o) => o.name !== variableInState.name)
        .value();

      const payload = toVariablePayload(variableInState, { metadata, variables });

      await dispatch(toKeyedAction(identifier.rootStateKey, updateMetadataOptions(payload)));
      await dispatch(validateVariableSelectionState(identifier));
    } catch (err) {
      console.error(err);
    }
  };
};

export const initMetadataVariableEditor =
  (
    key: string,
    dependencies: MetadataVariableActionDependencies = { getDashboardSrv: getDashboardSrv }
  ): ThunkResult<void> =>
  (dispatch) => {
    const dashboard = getDashboardSrv().getCurrent();
    const metadata = dashboard?.metadata || contextSrv.metadata;

    const metadataVariables = chain(metadata)
      .map((v) => keys(v))
      .flatten()
      .union()
      .value();

    const referenceVariables = chain(dashboard?.getVariables()).map('name').value();

    dispatch(
      toKeyedAction(
        key,
        changeVariableEditorExtended({
          metadataVariables: metadataVariables,
          referenceVariables: referenceVariables,
        })
      )
    );
  };
